// eslint-disable-next-line no-restricted-imports
import { format as dateFormat } from 'date-fns';
import { AvailableLocale } from '.';
import { dateFnsLocale } from './dateFnsLocale';
import { parseApplicationDate } from './parseApplicationDate';

export type FormatFn = (
  date: Parameters<typeof parseApplicationDate>[0],
  format: string,
) => string;

export const buildFormat =
  (locale: AvailableLocale): FormatFn =>
  (date, format) => {
    return dateFormat(parseApplicationDate(date), format, {
      locale: dateFnsLocale(locale || 'other'),
    });
  };

/**
 * @generated SignedSource<<e3851e50c78de7f00d607b4e10a4ee44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useThemeEditing_theme_Query$variables = {};
export type useThemeEditing_theme_Query$data = {
  readonly sessionInfo: {
    readonly tenant: {
      readonly id: string;
      readonly theme: {
        readonly id: string;
        readonly variables: ReadonlyArray<{
          readonly colorDark?: HexColorString;
          readonly colorLight?: HexColorString;
          readonly imageDark?: {
            readonly id: string;
            readonly url: URIString;
          };
          readonly imageLight?: {
            readonly id: string;
            readonly url: URIString;
          };
          readonly name: string;
          readonly rawValue?: string;
        }>;
      };
    } | null;
  };
};
export type useThemeEditing_theme_Query = {
  response: useThemeEditing_theme_Query$data;
  variables: useThemeEditing_theme_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "colorLight",
      "args": null,
      "kind": "ScalarField",
      "name": "light",
      "storageKey": null
    },
    {
      "alias": "colorDark",
      "args": null,
      "kind": "ScalarField",
      "name": "dark",
      "storageKey": null
    }
  ],
  "type": "Themes__Color",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawValue",
      "storageKey": null
    }
  ],
  "type": "Themes__Content",
  "abstractKey": null
},
v4 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "imageLight",
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "light",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": "imageDark",
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "dark",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Themes__Image",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useThemeEditing_theme_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionInfo",
        "kind": "LinkedField",
        "name": "sessionInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Themes__Theme",
                "kind": "LinkedField",
                "name": "theme",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "variables",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useThemeEditing_theme_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionInfo",
        "kind": "LinkedField",
        "name": "sessionInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Themes__Theme",
                "kind": "LinkedField",
                "name": "theme",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "variables",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebc5a7895087969bbae07c23d43d0959",
    "id": null,
    "metadata": {},
    "name": "useThemeEditing_theme_Query",
    "operationKind": "query",
    "text": "query useThemeEditing_theme_Query {\n  sessionInfo {\n    tenant {\n      id\n      theme {\n        id\n        variables {\n          __typename\n          name\n          ... on Themes__Color {\n            colorLight: light\n            colorDark: dark\n          }\n          ... on Themes__Content {\n            rawValue\n          }\n          ... on Themes__Image {\n            imageLight: light {\n              id\n              url\n            }\n            imageDark: dark {\n              id\n              url\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b80f4e88f04babe32ed6c1ea39faf99";

export default node;

import {
  Accordion,
  AccordionItem,
  AccordionProps,
  Box,
  BoxProps,
  useColorModeValue,
} from '@cardboard-ui/react';
import * as React from 'react';

export const Section = (props: BoxProps) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    shadow={{ base: '', sm: 'base' }}
    maxW="2xl"
    mx="auto"
    py="2"
    w="100%"
    borderColor={{ base: '', sm: useColorModeValue('gray.300', 'gray.500') }}
    borderRadius={{ base: 'none', sm: 'base' }}
    {...props}
  />
);

interface CollapsableSectionProps extends AccordionProps {
  hideAccordionItemBorder?: boolean;
}

export const CollapsableSection = ({
  children,
  hideAccordionItemBorder = false,
  ...props
}: CollapsableSectionProps) => (
  <Accordion
    allowToggle
    defaultIndex={[0]}
    bg={useColorModeValue('white', 'gray.700')}
    shadow={{ base: '', sm: 'base' }}
    maxW="2xl"
    mx="auto"
    w="100%"
    borderColor={{ base: '', sm: useColorModeValue('gray.300', 'gray.500') }}
    borderRadius={{ base: 'none', sm: 'base' }}
    {...props}
  >
    <AccordionItem
      {...(hideAccordionItemBorder
        ? { border: 'none' }
        : { borderBottomWidth: 0, borderTopWidth: 0 })}
    >
      {children}
    </AccordionItem>
  </Accordion>
);

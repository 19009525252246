import React, { useEffect } from 'react';
import { Center, forwardRef, Spinner, Stack } from '@cardboard-ui/react';
import { useTenantSession } from 'utils/sessionProvider';
import { t } from '@lingui/macro';
import { AuthenticationScreen } from '../AuthenticationScreen';
import { Navigate } from 'react-router-dom';
import { SIGN_IN_PATH, TENANT_DEFAULT_LANDING_PATH } from 'utils/routes';
import { AuthenticationScreenHeading } from '../AuthenticationScreenHeading';
import { usePersistedReturnToPath } from 'hooks/useReturnToPath';

interface AlreadySignedInProps {
  returnToPath?: string | null | undefined | false;
}

export const AlreadySignedIn = forwardRef<AlreadySignedInProps, 'div'>(
  ({ returnToPath }, ref) => {
    const { tenant } = useTenantSession();

    // We can always call this
    const [, , clearPersistedReturnToPath] = usePersistedReturnToPath();
    useEffect(clearPersistedReturnToPath);

    return (
      <AuthenticationScreen ref={ref}>
        <Stack>
          <AuthenticationScreenHeading>{t`Sign in to ${tenant.name}`}</AuthenticationScreenHeading>
          <Center>
            <Spinner />
          </Center>
          {returnToPath !== false && (
            <Navigate
              to={
                !returnToPath || returnToPath == SIGN_IN_PATH
                  ? TENANT_DEFAULT_LANDING_PATH
                  : returnToPath
              }
            />
          )}
        </Stack>
      </AuthenticationScreen>
    );
  },
);

export default AlreadySignedIn;

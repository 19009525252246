export { generatePath } from 'react-router-dom';

export const CANONICAL_ITEM_PARAM = 'itemId';
export const CANONICAL_SPACE_URL_PREFIX = 's';
export const CANONICAL_SPACE_PATH = '/s/:itemId';
export const CANONICAL_ITEM_URL_PREFIX = 'i';
export const CANONICAL_ITEM_PATH = '/i/:itemId';
export const CANONICAL_MEMBER_URL_PREFIX = 'u';
export const CANONICAL_MEMBER_PATH = '/u/:itemId';
export const CANONICAL_VAULT_URL_PREFIX = 'v';
export const CANONICAL_VAULT_PATH = '/v/:itemId';
export const CANONICAL_VAULT_ITEM_URL_PREFIX = 'x';
export const CANONICAL_VAULT_ITEM_PATH = '/x/:itemId';
export const CANONICAL_PRIVATE_VAULT_ITEM_URL_PREFIX = 'z';
export const CANONICAL_ANNOUNCEMENT_URL_PREFIX = 'a';
export const CANONICAL_ANNOUNCEMENT_PATH = '/a/:itemId';
export const CANONICAL_EVENT_URL_PREFIX = 'e';
export const CANONICAL_EVENT_PATH = '/e/:itemId';
export const CANONICAL_PERSON_URL_PREFIX = 'f';
export const CANONICAL_PERSON_PATH = '/f/:personId';

// Depricated below
export const CANONICAL_VAULT_EVENT_URL_PREFIX = CANONICAL_EVENT_URL_PREFIX;
export const CANONICAL_VAULT_ANNOUNCEMENT_URL_PREFIX =
  CANONICAL_ANNOUNCEMENT_URL_PREFIX;
export const CANONICAL_VAULT_PROFILE_URL_PREFIX = 'p';

export const CANONICAL_PREFIXES = [
  CANONICAL_SPACE_URL_PREFIX,
  CANONICAL_ITEM_URL_PREFIX,
  CANONICAL_MEMBER_URL_PREFIX,
  CANONICAL_VAULT_URL_PREFIX,
  CANONICAL_VAULT_ITEM_URL_PREFIX,
  CANONICAL_PRIVATE_VAULT_ITEM_URL_PREFIX,
  CANONICAL_EVENT_URL_PREFIX,
  CANONICAL_ANNOUNCEMENT_URL_PREFIX,
  CANONICAL_VAULT_PROFILE_URL_PREFIX,
  CANONICAL_PERSON_URL_PREFIX,
] as const;

export const TOKEN_PARAM = 'token';
export const SIGN_IN_PATH = '/sign-in';
export const SIGN_IN_TWO_FACTOR_PATH = '/sign-in/two-factor';
export const SIGN_IN_WITH_EMAIL_TOKEN_PATH = '/sign-in/with-email-token/:token';
export const SIGN_IN_WITH_SWITCH_TOKEN_PATH =
  '/sign-in/with-switch-token/:token';
export const SIGN_IN_WITH_GLOBAL_TOKEN_PATH =
  '/sign-in/with-global-token/:token';
export const SIGN_IN_WITH_GLOBAL_TOKEN_ACCOUNT_SELECT_PATH =
  '/sign-in/with-token-account-select/:token';
export const SIGN_OUT_PATH = '/sign-out';
export const SIGN_UP_PATH = '/sign-up';
export const SIGN_UP_CONFIRMATION_PATH = '/sign-up/:token';
export const SIGN_UP_COMPLETE_PATH = '/sign-up-complete';
export const SETUP_TWO_FACTOR_PATH = '/setup/two-factor';
export const SETUP_TENANT_CONFIRMATION_PATH = '/setup/account/:token';
export const SETUP_COMPLETE_PATH = '/setup/complete';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const CHANGE_PASSWORD_PATH = '/change-password';
export const RESET_PASSWORD_PATH = '/reset-password/:token';
export const VERIFY_EMAIL_PATH = '/verify-email/:token';

export const TERMS_AND_CONDITIONS_PATH = '/terms-and-conditions';
export const HOME_PATH = '/home';
export const NOTIFICATIONS_PATH = '/notifications';
export const BETA_PATH = '/beta';
export const MORE_PATH = '/more';
export const HELP_PATH = '/help';
export const CALENDAR_PATH = '/events';
export const FAVOURITES_PATH_NAME = 'favourites';
export const FAVOURITES_PATH = `/${FAVOURITES_PATH_NAME}`;
export const TODO_PATH = '/todo';
export const START_PATH = '/start';
export const STATISTICS_PATH = '/statistics';

export const VAULTS_PATH = '/vaults';
export const VAULTS_MANAGER_PATH_PREFIX = 'manager';
export const VAULTS_MANAGER_PATH = `${VAULTS_PATH}/${VAULTS_MANAGER_PATH_PREFIX}`;
export const VAULTS_MANAGER_DISTRIBUTIONS_PATH_PREFIX = 'distributions';
export const VAULTS_MANAGER_DISTRIBUTIONS_PATH = `${VAULTS_MANAGER_PATH}/${VAULTS_MANAGER_DISTRIBUTIONS_PATH_PREFIX}`;
export const VAULT_DISTRIBUTION_PARAM = 'distributionId';
export const VAULTS_MANAGER_DISTRIBUTION_PATH_PREFIX = `d/:${VAULT_DISTRIBUTION_PARAM}`;
export const VAULTS_MANAGER_DISTRIBUTION_PATH = `${VAULTS_MANAGER_DISTRIBUTIONS_PATH}/${VAULTS_MANAGER_DISTRIBUTION_PATH_PREFIX}`;
export const VAULTS_MANAGER_SETTINGS_PATH_PREFIX = 'settings';
export const VAULTS_MANAGER_SETTINGS_PATH = `${VAULTS_MANAGER_PATH}/${VAULTS_MANAGER_SETTINGS_PATH_PREFIX}`;
export const VAULT_PARAM = 'vaultId';
export const VAULTS_VAULT_PATH_PREFIX = `v/:${VAULT_PARAM}`;
export const VAULTS_MANAGER_VAULT_PATH = `${VAULTS_MANAGER_PATH}/${VAULTS_VAULT_PATH_PREFIX}`;
export const VAULTS_MY_VAULTS_VAULT_PATH = `${VAULTS_PATH}/${VAULTS_VAULT_PATH_PREFIX}`;
export const VAULTS_MY_VAULT_PATH_PREFIX = 'my-vault';
export const VAULTS_MY_VAULT_PATH = `${VAULTS_PATH}/${VAULTS_MY_VAULT_PATH_PREFIX}`;
export const VAULTS_MODE_PARAM = 'mode';
export const VAULTS_HIERARCHICAL_VAULT_PARAM = 'vault';
export const VAULTS_HIERARCHICAL_LABEL_PARAM = 'label';

export const PRIVATE_VAULT_PATH = `/private-vault`;

export const ORG_SETTINGS_PARAM_NAME = 'orgsetting';
export const TENNANT_SETTINGS_GENERAL_PATH_PREFIX = 'general';
export const TENNANT_SETTINGS_ACCESS_AND_ROLES_PATH_PREFIX = 'access-and-roles';
export const TENNANT_SETTINGS_SPACES_PATH_PREFIX = 'spaces';
export const TENNANT_SETTINGS_MEMBERS_PATH_PREFIX = 'members';
export const TENNANT_SETTINGS_FEATURES_PATH_PREFIX = 'features';
export const TENNANT_SETTINGS_SECURITY_AND_DATA_PATH_PREFIX = 'security';
export const TENNANT_SETTINGS_PLANS_AND_BILLING_PATH_PREFIX = 'billing';
export const TENNANT_SETTINGS_THEMES_PATH_PREFIX = 'themes';
export const TENNANT_SETTINGS_LOGS_PATH_PREFIX = 'logs';
export const TENNANT_SETTINGS_RESTORE_AND_PURGE_PATH_PREFIX =
  'restore-and-purge';
export const TENNANT_SETTINGS_MIGRATION_PATH_PREFIX = 'migration';
export const TENNANT_SETTINGS_PASSWORD_AND_AUTHENTICATION_PATH_PREFIX =
  'password-and-authentication';

export const MEMBER_PARAM = 'memberId';
export const MEMBER_PATH = '/members/:memberId';

export const EVENTS_PATH_PREFIX = 'events';
export const EVENTS_CALENDAR_MODE_PARAM = 'view_mode';
export const EVENT_PARAM = 'eventId';
export const EVENT_PATH = '/events/:eventId';
export const EVENT_ATTACHMENTS_PATH = '/events/:eventId/files';

export const ATTACHMENTS_PATH_PREFIX = 'files';

export const ANNOUNCEMENTS_PATH_PREFIX = 'announcements';

export const PROFILES_PATH_PREFIX = 'profiles';
export const PROFILE_PARAM = 'profileId';
export const MEMBERS_PATH_PREFIX = 'members';
export const FAMILY_TREE_PATH_PREFIX = 'family-tree';

export const SPACES_PATH = '/spaces';
export const SPACE_PATH = '/spaces/:spaceId';
export const SPACE_PARAM = 'spaceId';
export const SPACE_DELETED_PATH = 'deleted';
export const SPACE_SETTINGS_PATH = '/spaces/:spaceId/settings';
export const SPACE_EVENTS_PATH = '/spaces/:spaceId/events';
export const SPACE_ATTACHMENTS_PATH = '/spaces/:spaceId/files';
export const SPACE_ANNOUNCEMENTS_PATH = `/spaces/:spaceId/${ANNOUNCEMENTS_PATH_PREFIX}`;
export const SPACE_PROFILES_PATH = '/spaces/:spaceId/profiles';
export const SPACE_FAMILY_TREE_PATH = '/spaces/:spaceId/family-tree';

export const FAMILY_TREE_PERSON_PARAM = 'person';
export const FAMILY_TREE_SELECTED_PARAM = 'selected';
export const FAMILY_TREE_PERSON_IS_NEW = 'new';

export const SPACE_SETTINGS_PARAM_NAME = 'setting';
export const SPACE_SETTINGS_SPACEID_PARAM_NAME = 'spid';
export const SPACE_FILES_PARAM = 'folder';

export const SPACE_EVENT_PATH = '/spaces/:spaceId/events/:eventId';
export const SPACE_EVENT_ATTACHMENTS_PATH =
  '/spaces/:spaceId/events/:eventId/files';

export const SPACE_EVENTS_PATH_PREFIX = EVENTS_PATH_PREFIX;
export const SPACE_ATTACHMENTS_PATH_PREFIX = ATTACHMENTS_PATH_PREFIX;
export const SPACE_MEMBERS_PATH_PREFIX = MEMBERS_PATH_PREFIX;

export const CHAT_PATH = '/chat';
export const THREAD_PARAM = 'threadId';
export const INNER_CHAT_THREAD_PATH = `/:${THREAD_PARAM}`;
export const CHAT_THREAD_INFO_PATH_PREFIX = `info`;
export const INNER_CHAT_THREAD_INFO_PATH = `${INNER_CHAT_THREAD_PATH}/${CHAT_THREAD_INFO_PATH_PREFIX}`;

export const CHAT_LOOKUP_PARAM = 'lookupId';
export const CHAT_LOOKUP_FROM_NODE_PREFIX = 'fromNode';
export const INNER_CHAT_LOOKUP_FROM_NODE_PATH = `/${CHAT_LOOKUP_FROM_NODE_PREFIX}/:${CHAT_LOOKUP_PARAM}`;
export const CHAT_LOOKUP_SPACE_PREFIX = 'fromSpace';
export const INNER_CHAT_LOOKUP_SPACE_PATH = `/${CHAT_LOOKUP_SPACE_PREFIX}/:${CHAT_LOOKUP_PARAM}`;
export const CHAT_LOOKUP_DIRECT_PREFIX = 'directWith';
export const INNER_CHAT_LOOKUP_DIRECT_PATH = `/${CHAT_LOOKUP_DIRECT_PREFIX}/:${CHAT_LOOKUP_PARAM}`;

export const CHAT_THREAD_PATH = `${CHAT_PATH}${INNER_CHAT_THREAD_PATH}`;
export const CHAT_THREAD_INFO_PATH = `${CHAT_PATH}${INNER_CHAT_THREAD_INFO_PATH}`;
export const CHAT_LOOKUP_FROM_NODE_PATH = `${CHAT_PATH}${INNER_CHAT_LOOKUP_FROM_NODE_PATH}`;
export const CHAT_LOOKUP_SPACE_PATH = `${CHAT_PATH}${INNER_CHAT_LOOKUP_SPACE_PATH}`;
export const CHAT_LOOKUP_DIRECT_PATH = `${CHAT_PATH}${INNER_CHAT_LOOKUP_DIRECT_PATH}`;

export const asterisk = (s: string) => `${s}/*`;
export const pathParam = (param: string) => `:${param}`;

export const TENANT_DEFAULT_LANDING_PATH = HOME_PATH;

export const SETTINGS_DEFAULT_PATH = 'default';
export const MY_SETTINGS_QUERY_PARAM_NAME = 'ms';
export const ACCOUNT_SWITCHER_QUERY_PARAM_NAME = 'acs';
export const SEARCH_MODAL_QUERY_PARAM_NAME = 'q';
export const ITEM_VIEWER_QUERY_PARAM_NAME = 'i';
export const ITEM_VIEWER_EDIT_MODE_QUERY_PARAM_NAME = 'edit';
export const ANNOUNCEMENT_VIEWER_QUERY_PARAM_NAME = 'a';
// BUG: We use `modify` - instead of `edit` - here, as there is a bug in the itemViewer handeling
//      where it will remove the edit param all the time.
export const ANNOUNCEMENT_VIEWER_EDIT_MODE_PARAM_NAME = 'modify';

export const REQUEST_SIGNATURE_PARAM = 'rs';
export const FAMILY_TREE_VIEW_PARAM = 'view';

export const BOOL_TRUE_PARAM = '1';
export const BOOL_FALSE_PARAM = '0';

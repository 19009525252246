import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { clearNativeCsrfToken, getCsrfToken } from './getCsrfToken';
import * as Sentry from '@sentry/react';

interface Options {
  body?: string;
  method?: string;
  headers?: HttpHeaders;
  credentials?: RequestCredentials;
}
interface HttpHeaders {
  [key: string]: string;
}

export interface HttpResponse {
  json: () => Promise<any>;
  data: any;
  status: number;
  needsTwoFactorAuth: boolean;
  needsTwoFactorSetup: boolean;
}

function prefixUrl(url: string) {
  return Capacitor.isNativePlatform() && !url.startsWith('https')
    ? `https://${window.location.hostname}${url}`
    : url;
}

export async function httpRequest(
  url: string,
  options: Options,
): Promise<HttpResponse> {
  if (Capacitor.getPlatform() === 'android') {
    const res = await CapacitorHttp.request({
      url: prefixUrl(url),
      method: options.method || 'GET',
      data: options.body,
      headers: options.headers,
    });
    const twoFactorHeader = res.headers['x-require-two-factor-auth'];

    const data = res.data;

    return {
      json: async () => {
        if (typeof data === 'object') return data;
        else if (typeof data === 'string') return JSON.parse(data);
        else return undefined;
      },
      data,
      status: res.status,
      needsTwoFactorAuth: twoFactorHeader === 'true',
      needsTwoFactorSetup: twoFactorHeader === 'setup',
    };
  } else {
    const res = await fetch(prefixUrl(url), {
      method: options.method || 'GET',
      body: options.body,
      headers: options.headers,
      credentials: options.credentials,
    });
    const twoFactorHeader = res.headers.get('x-require-two-factor-auth');

    let data: string | undefined;
    try {
      data = await res.text();
    } catch (e) {
      data = undefined;
    }

    return {
      json: async () => {
        return data && JSON.parse(data);
      },
      data,
      status: res.status,
      needsTwoFactorAuth: twoFactorHeader === 'true',
      needsTwoFactorSetup: twoFactorHeader === 'setup',
    };
  }
}

export async function authenticatedHttpRequest(
  url: string,
  options: Options,
  attempts = 3, // 2 is minimum. We must allow for at least one retry
): Promise<HttpResponse> {
  let httpResponse: HttpResponse | undefined = undefined;
  let lastError: unknown = undefined;

  for (let i = 0; i < attempts; i++) {
    httpResponse = undefined;

    try {
      const csrfToken = await getCsrfToken({ refresh: i > 0 });

      const headers = {
        ...(csrfToken ? { ['X-CSRF-Token']: csrfToken } : {}),
        ...(options.headers || {}),
      };

      httpResponse = await httpRequest(url, {
        method: options.method || 'GET',
        body: options.body,
        headers,
        credentials: 'include',
      });
    } catch (err) {
      lastError = err;
      Sentry.withScope((s) => {
        s.setLevel('warning');
        s.setTags({
          http_error: 'authenticatedHttpRequest',
          http_retry: i,
          http_status: httpResponse?.status,
        });
        Sentry.captureException(err);
      });
      // Anything that can cause an issue, we will retry
    }

    if (!httpResponse || httpResponse.status !== 422) break;
    clearNativeCsrfToken();
    await sleep(100);
  }

  if (!httpResponse) {
    throw lastError || new Error('Invalid number of tries');
  }

  return httpResponse;
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

import { extendTheme } from '@cardboard-ui/react';

export const CUSTOM_THEME = extendTheme({
  initialColorMode: 'system',
  colors: {
    status: {
      error: '#DC3545',
      success: '#648719',
      warning: '#FFC107',
    },
    screen: {
      light: '#EDF2F7', //"colors.gray.100",
      dark: '#1A202C', //"colors.gray.800"
    },
  },
  components: {
    Modal: {
      variants: {
        fullMobile: {
          dialogContainer: {
            height:
              'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important',
            width:
              'calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right)) !important',
            top: 'env(safe-area-inset-top)',
            left: 'env(safe-area-inset-left)',
          },
          // This dialog minHeight is causing the modals to render behind the browser's bottom menu on mobile
          dialog: {
            minHeight:
              'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important',
            minWidth:
              'calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right)) !important',
          },
        },
      },
    },
  },
});

import React, { FC, PropsWithChildren } from 'react';
import { useSession } from './sessionProvider';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { useReturnToPath } from 'hooks/useReturnToPath';
import { useLocation } from 'react-router-dom';
import SignInModal from 'apps/TenantApp/screens/authentication/SignIn/components/SignInWithTenant';

export const RequireAuthentication: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, isReloading } = useSession();
  const [, setReturnToPath] = useReturnToPath();
  const location = useLocation();
  const newReturnToPath = window.location.href.replace(
    window.location.origin,
    '',
  );

  if (isReloading) {
    return <CenteredSpinner debugName="RequireAuthentication/isReloading" />;
  }

  if (!isAuthenticated) {
    return <AuthenticationRequired />;
  }

  return <>{children}</>;
};

const AuthenticationRequired = () => {
  return <SignInModal />;
};

/* eslint-disable no-restricted-imports */

import React from 'react';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  forwardRef,
} from '@chakra-ui/react';
import { isSafari } from 'utils/UserAgent';

export const Input = forwardRef<ChakraInputProps, 'input'>(
  ({ ...props }, ref) => {
    switch (props.type) {
      case 'date':
      case 'datetime-local':
      case 'month':
      case 'time':
      case 'week':
        if (!props.value && isSafari()) {
          props.style = {
            ...props.style,
            opacity: 0.3,
          };
        }
    }

    return <ChakraInput ref={ref} {...props} />;
  },
);

import { useOpenWithSearchParam } from 'hooks/useOpenWithSearchParam';
import { useEffect } from 'react';
import {
  ITEM_VIEWER_EDIT_MODE_QUERY_PARAM_NAME,
  ITEM_VIEWER_QUERY_PARAM_NAME,
  REQUEST_SIGNATURE_PARAM,
} from 'utils/routes';

interface OpenItemViewerOptions {
  [key: string]: string | undefined;
}

export const useOpenItemViewer = () => {
  const { onOpen, isOpen, onClose, openTo, paramValue } =
    useOpenWithSearchParam(ITEM_VIEWER_QUERY_PARAM_NAME, {
      removeParamsOnClose: [REQUEST_SIGNATURE_PARAM],
    });

  const {
    onOpen: startEditMode,
    isOpen: isEditModeEnabled,
    onClose: stopEditMode,
  } = useOpenWithSearchParam(ITEM_VIEWER_EDIT_MODE_QUERY_PARAM_NAME, {
    removeParamsOnClose: [REQUEST_SIGNATURE_PARAM],
  });

  useEffect(() => {
    if (!isOpen && isEditModeEnabled) {
      stopEditMode();
    }
  });

  return {
    openItemViewer: (itemId: string, options: OpenItemViewerOptions = {}) => {
      onOpen(itemId, options);
    },
    openItemTo: openTo,
    isOpen,
    itemId: paramValue,
    close: onClose,
    startEditMode: () => startEditMode('true'),
    stopEditMode,
    isEditModeEnabled,
  };
};

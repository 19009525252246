import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/react';
import { SuspendWithSpinner } from 'components/SuspendWithSpinner';
import useFetchKey from 'hooks/useFetchKey';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { clearNativeCsrfToken } from 'utils/getCsrfToken';
import { useDisclosure } from '@cardboard-ui/react';
// eslint-disable-next-line no-restricted-imports
import { graphql, useLazyLoadQuery } from 'utils/graphClient';
import { authenticatedHttpRequest } from 'utils/http';
import isDevMode from 'utils/isDevMode';
import {
  registerNotifications,
  requestPermission,
  shouldUpdateNotificationRegistration,
} from 'utils/push';
import { SIGN_OUT_PATH } from 'utils/routes';
import {
  FeatureFlagName,
  provider_SessionMemberInfo_Query,
  provider_SessionMemberInfo_Query$data,
} from './__generated__/provider_SessionMemberInfo_Query.graphql';
import {
  FeatureFlag,
  SessionContext,
  SessionContextInterface,
} from './context';
import { InitialPayloadType, RelayProvider } from './relayProvider';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { notEmpty } from 'utils/NotEmptyFilter';
import orderBy from 'lodash/orderBy';

const DEV_TOOLS_AVAILABLE_TENANTS = ['2AzSDnUTqwVnGbcV8rRsHQMm77fB'];

const SESSION_INFO_QUERY = graphql`
  query provider_SessionMemberInfo_Query($queryId: String) {
    queryId: echo(value: $queryId)
    sessionInfo {
      id
      __typename
      tenant {
        id
        name
        domain
        shortcode
        icon: image(width: 750) {
          url
        }
        canUpdate {
          value
        }
        languageCode
        hasPrivateVaults
      }
      activeFeatureFlags
      featureFlags {
        name
        enabled
        fullyEnabled
      }
      member {
        id
        trackingId
        email
        name
        notificationId
        avatar: image(width: 750) {
          url
        }
        languageCode
      }
      languageCode
      spaces {
        id
        publishState
        order
        modules {
          __typename
        }
      }
      signingData {
        signatures
        initials
      }
    }
  }
`;

const getGraphCurrentMemberId = async () => {
  if (Capacitor.isNativePlatform()) {
    clearNativeCsrfToken();
  }

  const response = await authenticatedHttpRequest('/graph', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      query: `
        query SessionMemberVerification {
          sessionInfo {
            member { id }
          }
        }
      `,
      variables: {},
    }),
  }).then((result) => result.json());

  return (response?.data?.sessionInfo?.member?.id || null) as string | null;
};

interface SessionProviderProps {
  children: ReactNode | ReactNode[] | null;
  needsTwoFactor: boolean;
  resetRequiresTwoFactor: () => void;
}

const InnerSessionProvider: FC<SessionProviderProps> = ({
  children,
  needsTwoFactor,
  resetRequiresTwoFactor,
}) => {
  const [reloadTriggerValue, updateReloadTriggerValue] = useFetchKey(),
    [isReloading, setIsReloading] = useState(false),
    [hideFeatureGlow, setHideFeatureGlow] = useState(false),
    [shouldRegisterNotification, setShouldRegisterNotification] =
      useState(false),
    navigate = useNavigate();

  const data = useLazyLoadQuery<provider_SessionMemberInfo_Query>(
    SESSION_INFO_QUERY,
    {
      queryId: `${reloadTriggerValue}`,
    },
    {
      fetchKey: reloadTriggerValue,
      fetchPolicy: 'store-and-network',
    },
  );

  const currentMemberId = data.sessionInfo.member?.id || null;
  const notificationId = data.sessionInfo.member?.notificationId || null;

  const authenticate = useCallback(() => {
    setIsReloading(true);
    updateReloadTriggerValue();
    setShouldRegisterNotification(true);
  }, []);

  const verifyAccount = useCallback(() => {
    getGraphCurrentMemberId()
      .then((graphCurrentMemberId) => {
        if (graphCurrentMemberId !== currentMemberId) {
          if (graphCurrentMemberId === null) {
            navigate(SIGN_OUT_PATH); // We signed out, so lets go there
          } else {
            window.resetAppState();
            setShouldRegisterNotification(true);
          }
        }
      })
      .catch(() => {
        window.resetAppState();
      });
  }, [currentMemberId, navigate]);

  const isTenantUrl = useCallback(
    (url: string) => {
      if (!data.sessionInfo.tenant) {
        throw new Error('Tenant is not set');
      }

      try {
        const urlObject = new URL(url);
        return urlObject.hostname === data.sessionInfo.tenant.domain;
      } catch {
        throw new Error('Invalid URL');
      }
    },
    [data.sessionInfo.tenant],
  );

  useEffect(() => {
    if (notificationId === null) return;

    shouldUpdateNotificationRegistration(notificationId).then((status) => {
      if (status) setShouldRegisterNotification(true);
    });
  }, [notificationId]);

  useEffect(() => {
    if (
      shouldRegisterNotification &&
      notificationId &&
      Capacitor.isNativePlatform()
    ) {
      requestPermission().then((hasPermission) => {
        if (hasPermission) {
          registerNotifications();
        }
        setShouldRegisterNotification(false);
      });
    }
  }, [notificationId, shouldRegisterNotification]);

  useEffect(() => {
    if (Capacitor.isNativePlatform() && data.sessionInfo.member?.email) {
      data.sessionInfo.member.email.endsWith('@trustedfamily.net')
        ? CapacitorUpdater.setChannel({ channel: 'staging' })
        : CapacitorUpdater.setChannel({ channel: 'production' });
    }
  }, [data.sessionInfo.member?.email]);

  const reloadOnVisible = useCallback(() => {
    if (document.visibilityState === 'visible') {
      verifyAccount();
    }
  }, [verifyAccount]);

  useEffect(() => {
    if (data.queryId === `${reloadTriggerValue}`) {
      setIsReloading(false);
    }
  }, [data.queryId, reloadTriggerValue]);

  useEffect(() => {
    document.addEventListener('visibilitychange', reloadOnVisible);
    return () => {
      document.removeEventListener('visibilitychange', reloadOnVisible);
    };
  }, [reloadOnVisible]);

  const [devToolsVisible, setDevToolsVisible] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<readonly FeatureFlag[]>(
    data.sessionInfo.featureFlags,
  );

  useEffect(() => {
    setFeatureFlags(data.sessionInfo.featureFlags);
  }, [data.sessionInfo.featureFlags]);

  useEffect(() => {
    const trackingId = data.sessionInfo.member?.trackingId || undefined;
    Sentry.setUser({ id: trackingId });
  }, [data.sessionInfo.member?.trackingId]);

  const devtoolsActive =
    (!!data.sessionInfo.tenant?.id &&
      DEV_TOOLS_AVAILABLE_TENANTS.includes(data.sessionInfo.tenant?.id)) ||
    isDevMode();

  // It is possible that the app is using older preloaded data, where the spaces would be missing.
  // In that case we will assume that the genealogy is not active.
  const spacesWihGenealogy = (data.sessionInfo.spaces || [])
    .filter(notEmpty) // It is possible that spaces are removed from the Relay internal store, this protects us from that
    .filter(({ publishState }) => publishState === 'PUBLISHED')
    .filter((s) =>
      s.modules.find((m) => m.__typename === 'SpaceFamilyTreeModule'),
    );
  const firstSpaceIdWithGenealogy = orderBy(spacesWihGenealogy, [
    (s) => s.order,
    'asc',
  ])[0]?.id;

  const genealogy = firstSpaceIdWithGenealogy
    ? { active: true as const, firstSpaceIdWithGenealogy }
    : { active: false as const };

  const privateVault = data.sessionInfo.tenant?.hasPrivateVaults
    ? { active: true as const }
    : { active: false as const };

  const sessionStatus = {
    isAuthenticated: !!currentMemberId,
    needsTwoFactor: needsTwoFactor && !isReloading && !currentMemberId,
    isReloading,
    signOutNow: () => navigate(SIGN_OUT_PATH),
    authenticate: () => {
      resetRequiresTwoFactor();
      authenticate();
    },
    isCurrentMember: (memberIsh?: { id: string } | null) => {
      return !!(
        memberIsh &&
        data.sessionInfo?.member &&
        data.sessionInfo?.member?.id === memberIsh?.id
      );
    },
    member: data.sessionInfo.member,
    tenant: data.sessionInfo.tenant,
    signingData: data.sessionInfo.signingData,
    isTenantUrl,
    featureFlags,
    resetAppState: window.resetAppState,
    devtools: {
      isVisible: devToolsVisible && devtoolsActive,
      isAvailable: devtoolsActive,
      show: () => {
        setDevToolsVisible(true);
      },
      hide: () => {
        setDevToolsVisible(false);
      },
      hideFeatureGlow: hideFeatureGlow && !devtoolsActive,
      setHideFeatureGlow,
      toggleFeature: (name: string) => {
        const feature = featureFlags.find((feature) => feature.name === name);
        if (feature) {
          const newFlags = [...sessionStatus.featureFlags];
          for (let i = 0; i < newFlags.length; i++) {
            if (newFlags[i].name === name) {
              newFlags[i] = { ...feature, enabled: !feature.enabled };
              break;
            }
          }
          setFeatureFlags(newFlags);
        }
      },
    },
    genealogy,
    privateVault,
  };

  return <SessionContext.Provider value={sessionStatus} children={children} />;
};

declare global {
  interface Window {
    resetAppState: () => void;
    __requireTwoFactor: () => void;
  }
}

export const SessionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [relayKey, refreshRelayKey] = useFetchKey();
  const resetAppState = useCallback(() => {
    window.clearPreloadDataFromWindow();
    refreshRelayKey();
  }, [refreshRelayKey]);
  const initialPayload = useMemo(getInitialRelayData, [relayKey]);

  const {
    isOpen: needsTwoFactor,
    onOpen: requiresTwoFactor,
    onClose: resetRequiresTwoFactor,
  } = useDisclosure();

  useEffect(() => {
    window.__requireTwoFactor = requiresTwoFactor;
  }, [requiresTwoFactor]);

  useEffect(() => {
    window.resetAppState = resetAppState;
  }, [resetAppState]);

  return (
    <RelayProvider key={relayKey} initialPayload={initialPayload}>
      <SuspendWithSpinner>
        <InnerSessionProvider
          key="session"
          needsTwoFactor={needsTwoFactor}
          resetRequiresTwoFactor={resetRequiresTwoFactor}
        >
          {children}
        </InnerSessionProvider>
      </SuspendWithSpinner>
    </RelayProvider>
  );
};

declare global {
  interface Window {
    initData?: RecordMap;
    preload_SessionMemberInfo_Query?: provider_SessionMemberInfo_Query$data;
    clearPreloadDataFromWindow: () => void;
  }
}

const getPreloadedDataFromWindow = () => {
  const data = window.preload_SessionMemberInfo_Query;

  if (data && data.sessionInfo) {
    return deepCopyWindowData(data);
  } else {
    return null;
  }
};

window.clearPreloadDataFromWindow = () => {
  window.preload_SessionMemberInfo_Query = undefined;
};

function deepCopyWindowData<P>(data: P) {
  if (!data) return data;
  return JSON.parse(JSON.stringify(data)) as P;
}

const getInitialRelayData: () => InitialPayloadType | undefined = () => {
  const payload = getPreloadedDataFromWindow();

  if (payload) {
    const operationDescriptor = createOperationDescriptor(
      getRequest(SESSION_INFO_QUERY),
      { queryId: '0' },
    );
    return { operationDescriptor, payload };
  } else {
    return undefined;
  }
};

export const useSession = () => useContext(SessionContext);
export const useFeatureFlag = (featureName: FeatureFlagName) =>
  useSession().featureFlags?.find(({ name }) => name === featureName)
    ?.enabled || false;
export const useFeatures = (featureName: FeatureFlagName) =>
  useSession().featureFlags?.find(({ name }) => name === featureName) || {
    name: featureName,
    enabled: false,
    fullyEnabled: false,
  };
export const useCardboardDevTools = () => useSession().devtools;

interface TenantSessionContextInterface
  extends Omit<SessionContextInterface, 'tenant'> {
  tenant: NonNullable<SessionContextInterface['tenant']>;
}

export const useTenantSession = () => {
  const session = useSession();

  if (!session.tenant) {
    throw new Error('useTenantSession requires tenant on the session');
  }

  return {
    tenant: session.tenant,
    ...session,
  } as TenantSessionContextInterface;
};

interface AuthenticatedSessionContextInterface
  extends Omit<TenantSessionContextInterface, 'member'> {
  member: NonNullable<TenantSessionContextInterface['member']>;
}

export const useAuthenticatedSession = () => {
  const session = useTenantSession();

  if (!session.member) {
    throw new Error('useAuthenticatedSession requires authenticates session');
  }

  return {
    member: session.member,
    ...session,
  } as AuthenticatedSessionContextInterface;
};
